<template>
  <img v-if="AbsImagePath" :src="AbsImagePath"
       :srcset="AbsImageSrcsetPath"
       width="100%">
</template>
<script type="text/babel">
export default {
  name: 'UploadedImage',
  props: {
    ImagePath: {},
    ImageCropFormatWidth: {
      type: String,
      default: '800',
    },
    ImageCropFormatHeight: {
      type: String,
      default: '400',
    },

  },
  computed: {
    AbsImagePath: function () {

      //console.log(process.env.apiPath);
      //console.log(process.env.BASE_URL);

      if (this.ImagePath && this.ImagePath.length){
        return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/' + this.ImageCropFormatWidth + 'x' + this.ImageCropFormatHeight + '/' + this.ImagePath
      } else {
        return ''
      }
    },
    AbsImageSrcsetPath: function () {

      //console.log(process.env.apiPath);
      //console.log(process.env.BASE_URL);

      if (this.ImagePath && this.ImagePath.length){
        /*

        needles-780.jpg  780w,   // Viewport bis zu 780
        needles-1024.jpg 1024w,  // Viewport größer als 780
        needles-1440.jpg 1440w,  // Viewport größer als 1024
        needles-2400.jpg 2400w"  // Viewport größer als 1440
         */
        return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/' + this.ImageCropFormatWidth + 'x' + this.ImageCropFormatHeight + '/' + this.ImagePath +' 780w, ' +
          '' + process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/' + (this.ImageCropFormatWidth*1.8) + 'x' + (this.ImageCropFormatHeight*1.8) + '/' + this.ImagePath +' 1024w'
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
  img {
    vertical-align: bottom;
    z-index: 80;
  }
</style>
