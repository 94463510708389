<template>
  <div class="slider">
    <carousel :per-page="1">
      <slide v-for="pic in pics" :key="pic.id">
        <div class="outer">
          <template v-if="language==='de'">
            <div class="slidetext" v-if="pic.name">
              {{ pic.name }}
            </div>
          </template>
          <template v-if="language==='pl'">
            <div class="slidetext" v-if="pic.namePL">
              {{ pic.namePL }}
            </div>
          </template>

          <UploadedImage :image-path="pic.dateiname" :image-crop-format-width="800" :image-crop-format-height="500" />
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
/*
  https://ssense.github.io/vue-carousel/guide/
   */
import {Carousel, Slide} from 'vue-carousel'
import UploadedImage from './UploadedImage'

export default {
  name: 'Slider',
  components: {
    UploadedImage,
    Carousel,
    Slide,
  },
  props: {
    pics: Array,

  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
  },

}
</script>

<style scoped lang="scss">
  .slider {
    max-width: 1024px;
    margin: 0 auto;
    padding-bottom: 80px;
    box-sizing: border-box;

    @media screen and (max-width: 480px) {
      padding-bottom: 40px;
    }
  }

  .slider img {
    width: 100%;
  }

  .slider .outer {
    position: relative;
  }

  .slider .slidetext {
    position: absolute;
    background-color: #bdbcbc;
    right: 0;
    bottom: 0;
    padding: 5px;
    color: #000;
  }

</style>

<style lang="scss">
  div.slider .VueCarousel-dot-container {

    margin-top: 0 !important;

  }

  div.slider button.VueCarousel-dot {

    margin-top: 0 !important;
    margin-bottom: 10px;
  }
</style>
