<template>
  <div class="karte">
    <l-map ref="map" :zoom="zoom" :center="center" :options="{scrollWheelZoom:false}">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="marker" />
    </l-map>
  </div>
</template>

<script>
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'

export default {
  name: 'Karte',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    lat: {},
    lon: {},
  },
  data() {
    return {
      map: null,
      zoom: 17,
      center: L.latLng(this.lat, this.lon),
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: L.latLng(this.lat, this.lon),
    }
  },
  mounted() {
    // https://github.com/gregallensworth/Leaflet/blob/master/LatLng_Bearings.js
    this.$nextTick(() => {
      this.map = this.$refs.map.mapObject // work as expected
      let map = this.map
      let usermarker = L.marker(map.getCenter()).addTo(map)
      let targetwaypoint = L.latLng(this.lat, this.lon)

      let linie = L.polyline([usermarker.getLatLng(), targetwaypoint], {color: 'red'}).addTo(map)

      this.map.on('locationfound', function (e) {
        //console.log(e);
        let radius = e.accuracy / 2
        usermarker.setLatLng(e.latlng).bindPopup('' + radius + ' meters away')
        // usermarker.setLatLng(e.latlng).bindPopup("" + radius + " meters away").openPopup();

        // L.marker(e.latlng).addTo(map).bindPopup("You are within " + radius + " meters from this point").openPopup();

        // L.circle(e.latlng, radius).addTo(map);

        // var bearingLine = [e.latlng, L.latLng(this.Lat, this.Lon)];
        // var pathBering = new L.Polyline(bearingLine);
        // pathBering.addTo(map)
        // draw the line between points
        // let _length_in_m = L.GeometryUtil.length([e.latlng, targetwaypoint]);
        // let _length_in_m = L.GeometryUtil.length(linie.getLatLng());
        // console.log('distance ' + _length_in_m + 'm');

        linie.setLatLngs([e.latlng, targetwaypoint])
      })
      this.map.locate({watch: true, enableHighAccuracy: true})

    })


  },
}
</script>

<style scoped lang="scss">
  .karte {
    height: 500px;

    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 80px;

    @media screen and (max-width: 480px) {
      height: 300px;
      margin-bottom: 40px;
    }
  }
</style>
