<template>
  <div class="waypoint">
    <HomeHeader :image-path="BPPoint.MainPic" :small="true" />

    <div class="infos">
      <template v-if="language=='de'">
        <h1>{{ BPPoint.city }}, {{ BPPoint.de.Titel }}</h1>
        <div class="notiz">{{ BPPoint.de.Notiz }}</div>
        <hr>
        <div v-html="BPPoint.de.Kurzbeschreibung"></div>
      </template>
      <template v-if="language=='pl'">
        <h1>{{ BPPoint.city }}, {{ BPPoint.pl.Titel }}</h1>
        <div class="notiz">{{ BPPoint.pl.Notiz }}</div>
        <hr>
        <div v-html="BPPoint.pl.Kurzbeschreibung"></div>
      </template>
      <template v-if="language=='cz'">
        <h1>{{ BPPoint.city }}, {{ BPPoint.cz.Titel }}</h1>
        <div class="notiz">{{ BPPoint.cz.Notiz }}</div>
        <hr>
        <div v-html="BPPoint.cz.Kurzbeschreibung"></div>
      </template>
    </div>


    <iframe v-if="BPPoint.mp" width='100%' height='480' :src='BPPoint.mp' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>


    <Karte :lat="BPPoint.latitude" :lon="BPPoint.longitude" />

    <Slider :pics="BPPoint.Bilder" />

    <Video v-if="BPPoint.videoid" :title="BPPoint.videotitel" :videoid="BPPoint.videoid" />
  </div>
</template>

<script type="text/babel">

import Video from '../components/Video'
import Karte from '../components/Karte'
import Slider from '../components/Slider'
import HomeHeader from '../components/HomeHeader'

export default {
  name: 'WaypointView',
  components: {HomeHeader, Slider, Karte, Video},
  props: ['waypointId'],
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
    BPPoint: function () {
      let self = this
      return this.$root.BPPoints.find((item) => item.id === self.waypointId)
    },
  },
}
</script>
