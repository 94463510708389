import { render, staticRenderFns } from "./waypoint.vue?vue&type=template&id=52bfa96a&"
import script from "./waypoint.vue?vue&type=script&lang=js&"
export * from "./waypoint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../.pnp/externals/pnp-12b91bfd89d82152724093e32982d62081f84c72/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports