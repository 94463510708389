import { render, staticRenderFns } from "./Karte.vue?vue&type=template&id=222f3299&scoped=true&"
import script from "./Karte.vue?vue&type=script&lang=js&"
export * from "./Karte.vue?vue&type=script&lang=js&"
import style0 from "./Karte.vue?vue&type=style&index=0&id=222f3299&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../.pnp/externals/pnp-12b91bfd89d82152724093e32982d62081f84c72/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222f3299",
  null
  
)

export default component.exports